<template>
  <v-card>
    <v-dialog
      v-model="openHelp"
      max-width="800px"
      :fullscreen="!full"
      scrollable
    >
      <membership-help @close="openHelp = false" />
    </v-dialog>
    <v-dialog
      v-model="openDialog"
      width="1080"
      persistent
      scrollable
      :fullscreen="!full"
    >
      <v-card title>
        <v-card-title>
          Duplicates found for Member ID: {{ selectedMemberId }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-3">
          <v-data-iterator
            :items="duplicated"
            item-key="_id"
            :items-per-page="duplicated.length"
            hide-default-footer
          >
            <template v-slot:default="{ items }">
              <v-row>
                <v-col
                  v-for="item in items"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card>
                    <v-card-title class="pt-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-checkbox
                              @click="selectedCard = item._id"
                              :value="selectedCard === item._id"
                              :label="item['Full Name']"
                            />
                          </span>
                        </template>
                        <span>Select for remains</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pa-0">
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-html="'Email:'" />
                            <v-list-item-subtitle
                              v-html="item['Email'] || defaultMsg"
                            />
                          </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-html="'Sequential ID:'" />
                            <v-list-item-subtitle
                              v-html="item['Legacy Id'] || defaultMsg"
                            />
                          </v-list-item-content>
                        </v-list-item> -->
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-html="'Member ID:'" />
                            <v-list-item-subtitle
                              v-html="selectedMemberId || defaultMsg"
                            />
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'Membership Expiration:'"
                            />
                            <v-list-item-subtitle
                              v-html="
                                format(item['Membership Expiration']) ||
                                  defaultMsg
                              "
                            />
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title v-html="'Address:'" />
                            <v-list-item-subtitle
                              v-html="item['Address']"
                              class="align-top"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="openDialog = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="proceed">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-card-text class="mt-2">
      <v-data-iterator
        :items="items"
        item-key="memberId"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title v-if="full">
              <v-icon class="pr-2">mdi-wallet-membership</v-icon>
              Duplicated Member Ids found
            </v-toolbar-title>
            <v-spacer />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text class="mr-2" outlined v-bind="attrs" v-on="on">
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageOpts"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span class="mr-4"> Page {{ page }} of {{ totalPages }} </span>
            <v-btn icon dark class="mr-1" @click="page = 1" small>
              <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
            <v-btn icon dark class="mr-1" @click="formerPage" small>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon dark class="ml-1" @click="nextPage" small>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn icon dark class="mx-1" @click="page = totalPages" small>
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
            <v-btn icon @click="openHelp = true">
              <v-icon>mdi-tooltip-question</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="loading"
            striped
            height="5"
          ></v-progress-linear>
        </template>
        <template v-slot:default="{ items }">
          <v-row class="mt-3">
            <v-col
              v-for="item in items"
              :key="item.memberId"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="py-2">
                  <h4>ID: {{ item.memberId }}</h4>
                  <v-spacer />
                  <v-chip color="red lighten-2 white--text" class="align-end">
                    {{ item.qty }}
                  </v-chip>
                </v-card-title>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    block
                    depressed
                    @click="getDuplicates(item.memberId)"
                  >
                    Resolve
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDate } from '../../helpers/format';
import { notifyConfirmation, notifyMessage } from '../../helpers/notifications';
import { getUserInfo } from '../../helpers/user';
import MembershipHelp from './HelpMembership.vue';
export default {
  components: { MembershipHelp },
  name: 'clearMembership',
  data: () => ({
    loading: false,
    totalPages: 0,
    openDialog: false,
    overlay: false,
    items: [],
    headers: Headers,
    page: 1,
    itemsPerPage: 30,
    itemsPerPageOpts: [30, 60, 90],
    duplicated: [],
    selectedCard: null,
    defaultMsg: 'No registered',
    selectedMemberId: null,
    openHelp: false
  }),
  watch: {
    page: {
      handler: 'getMemberships',
      immediate: true
    }
  },
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions('query', [
      'getAffectedMemberships',
      'getDuplicatedMemberships',
      'cleanMembership'
    ]),
    proceed() {
      if (!this.selectedCard) {
        notifyMessage('Select the user that will keep the membership');
        return;
      }

      const removed =
        this.duplicated
          ?.filter(item => item._id !== this.selectedCard)
          ?.map(item => item._id) || [];

      notifyConfirmation(
        'If you hit Proceed, the unselected user will lose their member Id and the selected user will keep their member Id',
        'Confirmation',
        async () => {
          this.overlay = true;
          const user = getUserInfo();
          const response = await this.cleanMembership({
            userId: this.selectedCard,
            removed,
            cleanedBy: user?.email
          });
          if (response) {
            notifyMessage('The membership was successfully cleaned');
            await this.getMemberships();
            this.openDialog = false;
            this.duplicated = [];
          } else notifyMessage('Something went wrong');
          this.overlay = false;
        },
        () => {},
        'Proceed'
      );
    },
    format(date) {
      return date ? formatDate(date) : '';
    },
    getMessage() {},
    async getMemberships() {
      this.loading = true;
      const result = await this.getAffectedMemberships({
        page: this.page || 1,
        limit: this.itemsPerPage || 30
      });

      const { docs, totalPages } = result?.data || {};
      this.items = docs;
      this.totalPages = totalPages;
      this.loading = false;
    },
    async getDuplicates(memberId) {
      this.openDialog = true;
      this.overlay = true;
      this.selectedMemberId = memberId;
      const result = await this.getDuplicatedMemberships({ memberId });

      const { docs } = result?.data || {};
      this.duplicated = docs;
      this.overlay = false;
    },
    async nextPage() {
      if (this.page + 1 <= this.totalPages) {
        this.page += 1;
      }
    },
    async formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
      }
    },
    async updateItemsPerPage(number) {
      this.itemsPerPage = number;
      await this.getMemberships();
    }
  }
};

const Headers = [
  {
    text: 'Member ID',
    align: 'start',
    sortable: false,
    value: 'memberId'
  },
  {
    text: 'Duplicates',
    align: 'start',
    sortable: false,
    value: 'qty'
  }
];
</script>
