<template>
  <v-card class="elevation-4">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>
        <v-icon class="pr-2">mdi-tooltip-question</v-icon>
        Feature Help
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-5">
      <h3>Member ID?</h3>
      <p>
        A Member ID, short for "Member Identification," is a unique identifier
        assigned to an individual within the Billfish's organization. It serves
        the primary purpose of distinguishing one member from another, ensuring
        that each member can be identified uniquely inside the memberships
        system.
      </p>

      <h3>Main Goal</h3>
      <p>
        Keeping the member IDs as unique parameters ensures that data remains
        accurate and consistent within the system. This identifier is a part of
        the integration with the Virtuous platform, which provides a unique and
        standardized way to identify and manage members. So, that's why it the
        important to resolve all conflicts related to it.
      </p>

      <h3>Overall Description</h3>
      <p>
        The “Clear Member IDs“ feature is a tool to find and resolve member ID
        duplicates. At the start, a list of duplicates is shown in order to be
        resolved one by one. Each item displays the member ID and in the top
        right corner shows the amount of duplicates found for that particular
        ID. A RESOLVE button allows you to fix the misunderstanding between
        users with the same member ID.
      </p>
      <p>
        Also, you have another way to mitigate or resolve this kind of issue. In
        the Manage Users section, you can edit a user profile and add or remove
        a member ID manually.
      </p>

      <h3>How it works</h3>
      <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          color="primary"
          :icon="item.icon"
          fill-dot
        >
          <v-card color="primary" dark>
            <v-toolbar color="primary">
              <v-toolbar-title>
                {{ item.title }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="white text--primary">
              <p>{{ item.text }}</p>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'membership-help',
  data: () => ({
    items: [
      {
        icon: 'mdi-numeric-1',
        title: 'SELECT THE ISSUE',
        text:
          'From the member ID issues list click RESOLVE of the issue you want to fix, and a new dialog will be prompted allowing you to choose the right data.'
      },
      {
        icon: 'mdi-numeric-2',
        title: 'SELECT THE RIGHT DATA',
        text:
          'For the selected issue a new dialog shows a list of involved users who have the same member ID, to make the right selection you need to check the whole information shown in every card and make sure who is the right owner of the member ID. You need to select or mark the right card and after that click CONTINUE.'
      },
      {
        icon: 'mdi-numeric-3',
        title: 'CONFIRM YOUR SELECTION',
        text:
          'Hitting the CONTINUE button will prompt a confirmation indicating the unselected users will lose their membership information. This action will clear only all membership-related information such as member ID, legacy level, membership level, membership status, expiration date, enrollment date, and donor ID from unselected users. All other data will remain untouched.'
      },
      {
        icon: 'mdi-check-bold',
        title: 'MEMBER ID CLEANED',
        text:
          'If no error comes out you will be notified that member ID is already cleaned and the issue will be no longer on the list.'
      }
    ]
  })
};
</script>
